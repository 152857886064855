import log from "loglevel";
import { put, takeEvery, call, select } from "redux-saga/effects";
import { canonicalPhone } from "common/helpers/helpers";
import without  from "lodash/without";

import {
  SITEEDIT_SUBMIT,
  SITEEDIT_SUCCESS,
  SITEEDIT_FAILURE,
  SITEEDIT_CLOSE,
  SITESUBSCRIPTION_SUBMIT,
  SITESUBSCRIPTION_SUCCESS,
  SITESUBSCRIPTION_FAILURE,
  SITEDELETE_SUBMIT,
  SITEDELETE_SUCCESS,
  SITEDELETE_FAILURE
} from "./SiteEditDuck";

import fetchApi from "utils/fetchApi";

const diff = (oldVal, newVal) =>
  oldVal !== newVal ||
  (oldVal && newVal && oldVal.toUpperCase() !== newVal.toUpperCase());

const setUnconditionally = (payload, field, value) =>
  value !== undefined ? value : (Array.isArray(payload[field]) ? [] : "");

const patchField = (payload, field, value) =>
  value !== undefined ? value : payload[field];

const setIfUndefined = (patch, ...rest) =>
  patch
    ? patchField(...rest)
    : setUnconditionally(...rest);

export function* fetchSiteEditSaga(action) {
  try {
    // redux-form-submit-saga injects 'payload' into action
    const {
      id,
      name,
      address1,
      address2,
      state,
      city,
      zipCode,
      primaryPhone,
      extension,
      latitude,
      longitude,
      companyId,
      types,
      typeChangeOnly
    } = action.payload;
    const url = `/Sites/${id}`;
    const authToken = yield select(state => state.login.authToken);
    const sites = yield select(state => state.companySites.data[companyId]);

    // Loopback has a problem patching Models, so we need to submit all field values.
    // To determine which fields have changed, a heuristic is used: if the call is
    // coming from the Site Edit form, we can use all old values modified by the form
    // values, and if a form field is empty, consider it should be empty in db as well.
    // If it's just a Site Type change, consider empty fields as unchanged, and don't 
    // delete them in db.
    const patch = typeChangeOnly ? true : false;

    const oldSite = sites[id];
    const {active, synced, id:deleted, ...payload} = oldSite;
    payload.address1 = setIfUndefined(patch, payload, 'address1', address1);
    payload.address2 = setIfUndefined(patch, payload, 'address2', address2);
    payload.state = setIfUndefined(patch, payload, 'state', state);
    payload.city = setIfUndefined(patch, payload, 'city', city);
    payload.zipCode = setIfUndefined(patch, payload, 'zipCode', zipCode);
    payload.extension = setIfUndefined(patch, payload, 'extension', extension);
    if(typeChangeOnly) payload.types = setIfUndefined(patch, payload, 'types', types);
    payload.primaryPhone = setIfUndefined(patch, payload, 'primaryPhone', canonicalPhone(primaryPhone));

    if (name) payload.name = name || oldSite.name || "";
    if (latitude && longitude) payload.latLng = { lat: latitude, lng: longitude };

    // call API
    const parsedJson = yield call(fetchApi, url, {
      payload,
      authToken,
      method: "PATCH"
    });

    if (!parsedJson.error) {
      // check if there are any new types for this Site:
      const hasAddedType = without(parsedJson.types, ...oldSite.types ).length > 0;

      // The backend will do a re-geocoding if any of these change, and set Site to unsynced.
      // Cases to consider: 
      //   1. only type change
      //   2. other metadata change
      const needsSync =
        !oldSite.synced || (typeChangeOnly 
          ? hasAddedType
          : diff(oldSite.city, parsedJson.city) ||
            diff(oldSite.state, parsedJson.state) ||
            diff(oldSite.address1, parsedJson.address1) ||
            diff(oldSite.zipCode, parsedJson.zipCode) ||
            !latitude ||
            !longitude ||
            hasAddedType
        );

      yield put({ type: SITEEDIT_SUCCESS, siteObj: parsedJson, needsSync });
      yield put({ type: SITEEDIT_CLOSE });
      if (needsSync){
        yield put({ 
          type: 'SITEPAGE_SET_PULSING_RUBRIC', 
          rubric: 'syncwait'
        })
      }
    } else {
      yield put({
        type: SITEEDIT_FAILURE,
        message: parsedJson.error.text._error
          ? parsedJson.error.text._error
          : parsedJson.error.text,
        payload: parsedJson.error.text
      });
    }
  } catch (error) {
    log.error("fetchSiteEditSaga error", error);
    yield put({
      type: SITEEDIT_FAILURE,
      message: "Network error."
    });
  }
}

export function* postSiteSubscriptionSaga({ siteId, subscriptionId }) {
  try {
    // redux-form-submit-saga injects 'payload' into action
    const authToken = yield select(state => state.login.authToken);
    const subs = yield select(state => state.subscriptionselector[siteId]);
    const payload = {
      activeSiteLimit: subs.activeSiteUnlimited ? "-1" : subs.activeSiteLimit,
      personLimit: subs.personUnlimited ? "-1" : subs.personLimit,
      active: subs.active,
      siteId: subs.siteId
    };
    const url = subscriptionId
      ? `/Subscriptions/${subscriptionId}`
      : "/Subscriptions";
    const method = subscriptionId ? "PUT" : "POST";

    const parsedJson = yield call(fetchApi, url, {
      payload,
      authToken,
      method
    });
    if (!parsedJson.error) {
      yield put({
        type: SITESUBSCRIPTION_SUCCESS,
        subscriptionObj: parsedJson
      });
      yield put({ type: SITEEDIT_CLOSE });
    } else {
      yield put({
        type: SITESUBSCRIPTION_FAILURE,
        message: parsedJson.error.text._error
          ? parsedJson.error.text._error
          : parsedJson.error.text,
        payload: parsedJson.error.text
      });
    }
  } catch (error) {
    log.error("putSiteSubscriptionSaga error", error);
    yield put({ type: SITESUBSCRIPTION_FAILURE, message: "Network error." });
  }
}

function* deleteSiteSaga({ id }) {
  try {
    const authToken = yield select(state => state.login.authToken);

    const result = yield call(fetchApi, `/Sites/${id}`, {
      authToken,
      method: "DELETE"
    });

    if (!result.error) {
      yield put({ type: SITEDELETE_SUCCESS, siteId: id });
    } else {
      yield put({ type: SITEDELETE_FAILURE });
    }
  } catch (error) {
    log.error("deleteSiteSaga error", error);
  }
}

// listen for actions of type SITEEDIT_SUBMIT and use them
export default function* siteEditSaga() {
  yield takeEvery(SITEEDIT_SUBMIT, fetchSiteEditSaga);
  yield takeEvery(SITESUBSCRIPTION_SUBMIT, postSiteSubscriptionSaga);
  yield takeEvery(SITEDELETE_SUBMIT, deleteSiteSaga);
}
